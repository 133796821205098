/* Declairations */ 
$very-dark-gray: #464646;
$noble-gray:  #a1a1a1;
$night-rider: #333333;
$dnd-title: #58180D;
$dnd-meta: #583e0d;
$black: #000000;

$body-color: $very-dark-gray;
$font-family-sans-serif:      system-ui, -apple-system, "Segoe UI", "Open Sans", "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !default;
$headings-font-family: "Playfair Display" !default;

/* links */ 
$link-decoration: underline !default;

/* colors */
$primary: #6e0000; // #ca9b52;
$success: #566E3D;
$info: #e0e5c1;
$dark: #333333;
$danger: #440D13;

$fa-font-path: "../webfonts";
$enable-shadows: true;
