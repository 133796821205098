@import url('https://fonts.googleapis.com/css2?family=Noto+Sans&display=swap');
@import 'local_variables';

@import "bootstrap-5.2.3/bootstrap";
@import "statblock";
@import "sd-statblock";
@import "fontawesome/fontawesome";
@import "fontawesome/solid";
@import "fontawesome/brands";
@import "fontawesome/regular";
@import "main";
