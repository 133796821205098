@import url('https://fonts.googleapis.com/css2?family=Noto+Sans&family=Open+Sans&family=Playfair+Display&display=swap');

.parallax-window {
    // min-height: 500px;
    background: transparent;
}

.header-logo {
    padding-top: 120px;
}

a.site-title, .post-title a {
        color: $night-rider !important;
}

.post-title a:hover {
    color: $black !important;
    filter: brightness(1.2);
}
.post-subtitle {
    color: $noble-gray;
    font-size: 1.5rem;
    font-weight: 400;
    margin-bottom: 1rem;
}

.post-content > p {
    text-align: justify;
}
.post-meta {
    color: $dark;
    font-style: italic;
}

.site-title {
    font-family: 'Playfair Display' serif;
    letter-spacing: -1px;
    font-weight: 700;
}

.site-title {
    @include font-size(7.5rem);
}

.post-content > p:first-of-type:first-letter {
    float: left;
    margin: 0px 12px 0 0;
    font-family: 'Playfair Display';
    font-size: 80px;
    line-height: 65px;
    text-align: center;
    text-transform: uppercase;
    color: #030303;
}

.post-categories a {
    text-transform: uppercase;
    letter-spacing: 2px;
    font-size: 12px;
    font-weight: bold;
}

.widget-title {
    position: relative;
    z-index: 0;
    text-align: center;
    margin-bottom: 12px;
    clear: both;
    overflow: hidden;
    font-family: "Playfair Display";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    letter-spacing: 1.5px;
    line-height: 28.8px;
    text-transform: uppercase;
}
  
  /* Video Embeds */
  .embed-container {
    position: relative;
    padding-bottom: 56.25%;
    padding-top: 35px;
    height: 0;
    overflow: hidden;
}
.embed-container iframe {
    position: absolute;
    top:0;
    left: 0;
    width: 100%;
    height: 100%;
}

.data-table tbody tr td,
.data-table thead tr th {
    text-align: center;
}

.full-first tbody tr td:first-child,
.full-first thead tr th:first-child {
    white-space: nowrap;
}


thead tr th:first-child,
tbody tr td:first-child {
  text-align: left;
}

// Remove sections in print view.
@media print {
    .d-print-none {
     display: none !important;
    }
    .parallax-mirror { // The parallax.js library inserts this class so needs to be set specifically here.
        display: none !important;
    }
   }

   
.callout {
    padding: 20px;
    margin: 20px 0;
    border: 1px solid #eee;
    border-left-width: 5px;
    border-radius: 3px;
    border-left-color: #eee; /* Set this to whatever color you want */
    background-color: #fafafa;
}

.callout-info {
    border-left-color: #17a2b8;
}
.callout-parchment {
    background-color: #EBD5B3;
    border-left-color: #E0C9A6;
}

.callout h3{
    margin-top: .2em;
    margin-bottom: .2em;
    border-bottom: 1px solid #822000;
    color: #822000;
    font-size: 24px;
    font-weight: normal;
    line-height: 1.4;
  }

  .rule {
    margin:20px auto;
    padding: 1.5em;
    border-radius:5px;
    box-shadow:0 0 15px 5px #ccc;
  }

  @mixin rule($name,$bgColor){
    $accentColor:darken($bgColor,50);
    .#{$name}{
      background-color:#{$bgColor};
      border-left:5px solid $accentColor;
      .close{
        border-color:$accentColor;
        color:$accentColor;
      }
    }
  }
  @include rule(simple-rule,#ebebeb);
  @include rule(success-rule,#a8f0c6);
  @include rule(danger-rule,#f7a7a3);
  @include rule(warning-rule,#ffd48a);

  .rule h2 {
    font-family: 'Libre Baskerville', 'Lora', 'Calisto MT', 'Bookman Old Style', Bookman, 'Goudy Old Style', Garamond, 'Hoefler Text', 'Bitstream Charter', Georgia, serif;
    font-size: .705cm;
    // margin-top: -8px;
     margin-bottom: 0;
    font-weight: normal;
    color: #58180D;
    line-height: 1.1;
  }
  .rule h3 {
    margin-top: .2em;
    margin-bottom: .2em;
    border-bottom: 1px solid #822000;
    color: #822000;
    font-size: 24px;
    font-weight: normal;
    line-height: 1.4;
  }
  .rule h4 {
    margin-top: .2em;
    margin-bottom: .2em;
    font-size:18px;
    font-weight: normal;
    line-height: 1.2;
  }

.share-bar a {
    margin-right: 10px;
    font-size: 1.1em;
}
.facebook-blue {
    color: #3b5998;
}
.twitter-blue {
    color: #1da1f2;
}
.reddit-orange {
    color: #ff4500;
}
.email-red {
    color: #d44638;
}

// Cookie Consent
.cookie-consent-banner {
    display: none;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: $noble-gray;
    box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
    color: black;
    padding: 15px;
    padding-bottom: 30px;
    text-align: center;
    z-index: 1000;
}
